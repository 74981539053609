import { getTailwindTheme } from 'src/config';
import { useDimensions } from './useDimensions';

// ----------------------------------------------------------------------

const useDevice = () => {
  const { width } = useDimensions();
  const theme = getTailwindTheme();

  const screenWidths = Object.keys(theme.screens || {}).reduce(
    (screenWidths, key) => {
      const value = theme.screens[key].replace('px', '');
      screenWidths[key] = Number(value || 0);
      return screenWidths;
    },
    {} as { [key in keyof typeof theme.screens]: number }
  );

  const isMobile =
    navigator['userAgentData']?.mobile || width <= screenWidths.md;
  const isTablet =
    width > screenWidths.md && width <= screenWidths.lg;

  return {
    isMobile: isMobile,
    isOnlyMobile: isMobile,
    isDesktop: !isMobile,
    isOnlyDesktop: width > screenWidths.lg,
    isTablet: isTablet,
    isOnlyTablet: isTablet
  };
};

export default useDevice;
