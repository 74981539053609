import { useRouter } from 'next/router';
import { ROUTES } from 'src/config';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import { alpha, getPalette } from 'src/theme/palette';

// ----------------------------------------------------------------------

export function Logo() {
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);
  return (
    <div
      style={{
        background: alpha(palette.primary.main, 1),
        width: '283px',
        height: '200px',
        WebkitMaskImage: 'url(/static/emitiq/eiq_logo.svg)',
        maskImage: 'url(/static/emitiq/eiq_logo.svg)',
        boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.75)'
      }}
    />
  );
}

export function TopbarLogo() {
  const router = useRouter();
  return (
    <div
      className="cursor-pointer px-lg lg:px-xl xl:px-lg"
      onClick={() => {
        router.push(ROUTES.ROOT);
      }}
    >
      <div className="w-[140px]">
        <img
          src={'/static/emitiq/eiq_logo_horz.svg'}
          className="w-full rounded-lg object-fill"
        />
      </div>
    </div>
  );
}
