import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export function useDimensions() {
  const hasWindow = typeof window !== 'undefined';

  function getDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height
    };
  }

  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setDimensions(getDimensions());
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return dimensions;
}
