import { SubscriptionPlanTiersEnum } from 'src/__apolloGenerated__/graphql';
import { SubscriptionPlanType } from 'src/components/core/organisms/dialogs/SubscribeDialog';
import { create } from 'zustand';

export type SubscriptionStoreType = {
  activeSubscriptionTier: SubscriptionPlanTiersEnum;
  setActiveSubscriptionTier: (
    newState: SubscriptionPlanTiersEnum
  ) => void;
  paywallDialogOpen: boolean;
  setPaywallDialogOpen: (newState: boolean) => void;
  requiredPlan: SubscriptionPlanType;
  setRequiredPlan: (newState: SubscriptionPlanType) => void;
  subtitleText: string;
  setSubtitleText: (newState: string) => void;
};

const useSubscriptionStore = create<SubscriptionStoreType>((set) => ({
  activeSubscriptionTier: null,
  setActiveSubscriptionTier: (newState) =>
    set({ activeSubscriptionTier: newState }),
  paywallDialogOpen: false,
  setPaywallDialogOpen: (newState) =>
    set({ paywallDialogOpen: newState }),
  requiredPlan: null,
  setRequiredPlan: (newState) => set({ requiredPlan: newState }),
  subtitleText: '',
  setSubtitleText: (newState) => set({ subtitleText: newState })
}));

export default useSubscriptionStore;
