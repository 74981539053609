import { create } from 'zustand';

export type AppStoreType = {
  isScrollButtonVisible: boolean;
  setIsScrollButtonVisible: (newState: boolean) => void;
  isOpen: boolean;
  setIsOpen: (newState: boolean) => void;
  subscribeDialogOpen: boolean;
  setSubscribeDialogOpen: (newState: boolean) => void;
  searchDialogOpen: {
    open: boolean;
    navigate: boolean;
  };
  setSearchDialogOpen: (newState: {
    open: boolean;
    navigate: boolean;
  }) => void;
  sidebarMinimized: boolean;
  setSidebarMinimized: (newState: boolean) => void;
  panelMinimized: boolean;
  setPanelMinimized: (newState: boolean) => void;
};

const useAppStore = create<AppStoreType>((set) => ({
  isScrollButtonVisible: true,
  setIsScrollButtonVisible: (newState) => {
    set({ isScrollButtonVisible: newState });
  },
  isOpen: false,
  setIsOpen: (newState) => {
    set({ isOpen: newState });
  },
  subscribeDialogOpen: false,
  setSubscribeDialogOpen: (newState) => {
    set({ subscribeDialogOpen: newState });
  },
  searchDialogOpen: {
    open: false,
    navigate: false
  },
  setSearchDialogOpen: (newState) => {
    set({ searchDialogOpen: newState });
  },
  sidebarMinimized: false,
  setSidebarMinimized: (newState) => {
    set({ sidebarMinimized: newState });
  },
  panelMinimized: false,
  setPanelMinimized: (newState) => {
    set({ panelMinimized: newState });
  }
}));

export default useAppStore;
