import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
  useMutation
} from '@apollo/client';
// import Bugsnag from '@bugsnag/js';
import { IS_LOCAL_DEV_ENVIRONMENT } from 'src/config';
import { MutateBackendType } from 'src/hooks/useBackendMutation/@types';
import getIntercom, { INTERCOM_EVENTS } from 'src/utils/intercom';
import useAppStore, { AppStoreType } from '../store/useAppStore';
import useAuthPersistStore, {
  AuthStoreType
} from '../store/useAuthPersistStore';
import { toast } from '../shad-base/useToast';

// ----------------------------------------------------------------------

function DefaultErrorHandlers(
  setSubscribeDialogOpen,
  isAuthenticated
) {
  return {
    authenticationError: (error) => {
      toast({
        title: 'Error.',
        description: error.message,
        variant: 'destructive'
      });
    },
    authorizationError: (error) => {
      toast({
        title: 'Error.',
        description: error.message,
        variant: 'destructive'
      });
    },
    rateLimitError: (error) => {
      toast({
        title: 'Error.',
        description: error.message,
        variant: 'destructive'
      });
    },
    notFoundError: (error) => {
      toast({
        title: 'Error.',
        description: error.message,
        variant: 'destructive'
      });
    },
    subscriptionError: (error) => {
      if (isAuthenticated) {
        setSubscribeDialogOpen(true);
      } else {
        toast({
          title: 'Error.',
          description: error.message,
          variant: 'destructive'
        });
      }
    },
    genericErrors: (error) => {
      toast({
        title: 'Error.',
        description: error.message,
        variant: 'destructive'
      });
    },
    fieldErrors: (error) => {
      toast({
        title: 'Error.',
        description: error.message,
        variant: 'destructive'
      });
    }
  };
}

function handleErrors(errors, errorHandlers) {
  if (!errors) {
    return;
  }

  if (
    errors.authenticationError &&
    errorHandlers.authenticationError
  ) {
    errorHandlers.authenticationError(errors.authenticationError);
  }
  if (errors.authorizationError && errorHandlers.authorizationError) {
    errorHandlers.authorizationError(errors.authorizationError);
  }
  if (errors.rateLimitError && errorHandlers.rateLimitError) {
    errorHandlers.rateLimitError(errors.rateLimitError);
  }
  if (errors.notFoundError && errorHandlers.notFoundError) {
    errorHandlers.notFoundError(errors.notFoundError);
  }
  if (errors.subscriptionError && errorHandlers.subscriptionError) {
    errorHandlers.subscriptionError(errors.subscriptionError);
  }
  if (
    errors.genericErrors &&
    errors.genericErrors.length > 0 &&
    errorHandlers.genericErrors !== undefined
  ) {
    errors.genericErrors.forEach((error) => {
      errorHandlers.genericErrors(error);
    });
  }
  if (
    errors.fieldErrors &&
    errors.fieldErrors.length > 0 &&
    errorHandlers.fieldErrors
  ) {
    errors.fieldErrors?.forEach((fieldError) => {
      if (fieldError?.helpMessage) {
        toast({
          title: 'Error help message:',
          description: fieldError?.helpMessage,
          variant: 'destructive'
        });
      }
    });
    errorHandlers.fieldErrors(errors.fieldErrors);
  }
}

//--------------------------------------------------------------------------

export type MutationType<T> = (
  options?: MutationFunctionOptions<
    T,
    OperationVariables,
    DefaultContext,
    ApolloCache<T>
  >
) => Promise<T>;

export default function useBackendMutation<Input, Payload>({
  mutation,
  variables,
  errorHandlers,
  callbacks = {},
  disableErrorSnackbar = false,
  errorPolicy = 'none'
}: MutateBackendType<Input, Payload>) {
  const { authState } = useAuthPersistStore(
    (store: AuthStoreType) => ({
      authState: store.authState
    })
  );
  const { setSubscribeDialogOpen } = useAppStore(
    (store: AppStoreType) => ({
      setSubscribeDialogOpen: store.setSubscribeDialogOpen
    })
  );
  const [mutate, { loading }] = useMutation(mutation, {
    variables,
    errorPolicy,
    onError: (error) => {
      if (IS_LOCAL_DEV_ENVIRONMENT) {
        // eslint-disable-next-line
        console.log(error);
      }
      if (callbacks && callbacks.onError) {
        callbacks.onError();
      }
      if (!disableErrorSnackbar) {
        toast({
          title: 'Error.',
          description:
            "Oops! We've been alerted and are working on a fix.",
          variant: 'destructive'
        });
      }
    },
    onCompleted: (response, serverErrors) => {
      const data = response
        ? response[Object.keys(response)[0]]
        : null;

      if (IS_LOCAL_DEV_ENVIRONMENT)
        // eslint-disable-next-line
        console.log('onCompleted response: ', data);

      // Server error has occured
      if (!data) {
        if (serverErrors) {
          getIntercom().trackEvent(INTERCOM_EVENTS.BUG_ENCOUNTERED, {
            message: serverErrors[0].message
          });
        }
        // if (ENVIRONMENT === 'production') {
        //   // bugsnag
        //   const bugsnagCallback = function (event) {
        //     event.addMetadata('details', {
        //       input: {},
        //       response: response,
        //       errors: serverErrors
        //     });
        //   };
        //   Bugsnag.addOnError(bugsnagCallback);
        //   Bugsnag.notify(new Error(mutation['default'].params.name));
        //   Bugsnag.removeOnError(bugsnagCallback);
        // }
        toast({
          title: 'Server Error.',
          description:
            "Oops! We've been alerted and are working on a fix.",
          variant: 'destructive'
        });
        if (callbacks && callbacks.onError) {
          callbacks.onError();
        }
        return;
      }
      const errors = data.errors;

      // Run callback if commit succeeds
      if (callbacks && !data.errors) {
        const asyncCallbacks = [];

        // First callback (useful for rendering components)
        if (callbacks && callbacks.onSuccess) {
          asyncCallbacks.push(callbacks.onSuccess(data));
        }

        Promise.all(asyncCallbacks).catch(({ ...objs }) => {
          if (IS_LOCAL_DEV_ENVIRONMENT)
            // eslint-disable-next-line
            console.error('onSuccess or onSuccessDispatch errors', {
              ...objs
            });
        });

        // Errors occurred
      } else if (data.errors) {
        errorHandlers = {
          ...DefaultErrorHandlers(
            setSubscribeDialogOpen,
            authState.isAuthenticated
          ),
          ...(errorHandlers || {})
        };

        handleErrors(errors, errorHandlers);
        if (callbacks && callbacks.onError) {
          callbacks.onError();
        }
      }
    }
  });
  return { loading, mutate };
}
